import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import { Route, Routes } from "react-router-dom";
import "./styles/App.css";
import Button from "react-bootstrap/Button";
import Dashboard01 from "./views/dashboards/Dashboard01";
import Dashboard03 from "./views/dashboards/Dashboard03";
import Dashboard04 from "./views/dashboards/Dashboard04";
import EnrollmentDocuments from "pages/enrollments/EnrollmentDocuments";
import GenerateEmailSignaturePage from "./pages/tools/GenerateEmailSignaturePage";
import MaintenanceBanners from "pages/tools/MaintenanceBanners";
import DashTechApp01 from "./views/dashboards/DashTechApp01";
import DashTechHdw01 from "./views/dashboards/DashTechHdw01";
import { EnrollmentDetails } from "pages/enrollments/EnrollmentDetails";
import { EnrollmentPortal } from "pages/enrollments/EnrollmentPortal";
import { SalesCategory } from "pages/brokers/SalesCategory";
import {Account} from "./pages/accounts/Account";
import {Active} from "./pages/enrollments/Active";
import {BrokerApiDocs} from "./pages/brokers/BrokerApiDocs";
import {BrokerApiEnrollments} from "./pages/brokers/BrokerApiEnrollments";
import {BrokerApiIncomplete} from "./pages/brokers/BrokerApiIncomplete";
import {BrokerEnrollments} from "./pages/brokers/BrokerEnrollments";
import {BrokerIncomplete} from "./pages/brokers/BrokerIncomplete";
import {BrokerManagement} from "./pages/brokers/BrokerManagement";
import {BrokerProductList} from "./pages/brokers/BrokerProductList";
import {BrokerSummary} from "./pages/brokers/BrokerSummary";
import {DepositRequired} from "./pages/enrollments/DepositRequired";
import {Dropped} from "./pages/enrollments/Dropped";
import {Enrollments} from "./pages/enrollments/Enrollments";
import {FraudReview} from "./pages/fraud/FraudReview";
import {Incomplete} from "./pages/enrollments/Incomplete";
import {NewEnrollment} from "./pages/enrollments/NewEnrollment";
import {PartnerList} from "./pages/partners/PartnerList";
import {Prices} from "./pages/tools/Prices";
import {CheckEmailValidity} from "./pages/tools/CheckEmailValidity";
import {RenewalNotice} from "./pages/tools/RenewalNotice";
import {TduforAccount} from "./pages/tools/TduforAccount";
import {Products} from "./pages/tools/Products";
import {PromoCodes} from "./pages/tools/PromoCodes";
import {RenewEligible} from "./pages/renewals/RenewEligible"
import {SalesReport} from "./pages/brokers/SalesReport"
import {SmsTemplates} from "./pages/tools/SmsTemplates";
import {TicketsReview} from "./pages/tech/TicketsReview";
import {VideoVetChatlog} from "./pages/videovet/VideoVetChatlog";
import {PendingMyAccount} from "./pages/accounts/PendingMyAccount";
import Dashboard05 from "./views/dashboards/Dashboard05";
import Dashboard06 from "./views/dashboards/Dashboard06";


/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
    }
    return (
        <>
            <h5 id="acc_tittle" className="card-title">Welcome {accounts[0] ? accounts[0].name : ''}</h5>
            { graphData ?
                <ProfileData graphData={graphData} />
                :
                <>
                    {/*<Button variant="secondary" onClick={RequestProfileData}>Request Profile Information</Button>*/}
                    <Routes>
                        <Route path="/" element={<Dashboard01 />} />
                        <Route path="/account/lookup" element={<Account />} />
                        <Route path="/account/pending-myaccount" element={<PendingMyAccount />} />
                        <Route path="/active" element={<Active />} />
                        <Route path="/incomplete" element={<Incomplete />} />
                        <Route path="/enrollments" element={<Enrollments />} />
                        <Route path="/fraud-review" element={<FraudReview />} />
                        <Route path="/enrollment-portal" element={<EnrollmentPortal />} />
                        <Route path="/enrollment-documents" element={<EnrollmentDocuments />} />
                        <Route path="/enrollment-details" element={<EnrollmentDetails />} />
                        <Route path="/new-enrollment" element={<NewEnrollment />} />
                        <Route path="/enrollments/qs-db-04" element={<Dashboard04/>}/>
                        <Route path="/dropped" element={<Dropped />} />
                        <Route path="/deposit-required" element={<DepositRequired />} />
                        <Route path="/broker-summary" element={<BrokerSummary />} />
                        <Route path="/broker-enrollments" element={<BrokerEnrollments />} />
                        <Route path="/broker-incomplete" element={<BrokerIncomplete />} />
                        <Route path="/sales-report" element={<SalesReport />} />
                        <Route path="/sales-category" element={<SalesCategory />} />
                        <Route path="/broker-api-enrollments" element={<BrokerApiEnrollments />} />
                        <Route path="/broker-api-incomplete" element={<BrokerApiIncomplete />} />
                        <Route path="/broker-api-docs" element={<BrokerApiDocs />} />
                        <Route path="/broker-mnt" element={<BrokerManagement />} />
                        <Route path="/broker-product-list" element={<BrokerProductList />} />
                        <Route path="/renewals" element={<RenewEligible />} />
                        <Route path="/partner-list" element={<PartnerList />} />
                        <Route path="/generate-email-signature" element={<GenerateEmailSignaturePage />} />
                        <Route path="/settings/enrollment" element={<Prices />} />
                        <Route path="/promocodes" element={<PromoCodes />} />
                        <Route path="/sms-templates" element={<SmsTemplates />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/videovet" element={<VideoVetChatlog />} />
                        <Route path="/tech/dashapp01" element={<DashTechApp01/>}/>
                        <Route path="/tech/dashhdw01" element={<DashTechHdw01/>}/>
                        <Route path="/renewals/qs-db-03" element={<Dashboard03/>}/>
                        <Route path="/renewals/qs-db-06" element={<Dashboard06/>}/>
                        <Route path="/accounting/qs-db-05" element={<Dashboard05/>}/>
                        <Route path="/tech/tk-review/eh" element={<TicketsReview filterId={10040}/>}/>
                        <Route path="/tech/tk-review/qw" element={<TicketsReview filterId={10046}/>}/>
                        <Route path="/maintenance-banners" element={<MaintenanceBanners />} />
                        <Route path="/settings/checkemail" element={<CheckEmailValidity />} />
                        <Route path="/settings/renewalnotice" element={<RenewalNotice />} />
                        <Route path="/settings/tduforaccount" element={<TduforAccount />} />
                    </Routes>
                </>
            }
        </>
    );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to to GoodCharlie InternalApp.</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
